<template>
  <div class="conatiner">
    <Navbar />

    <NewsCards />

    <Footer />
  </div>
</template>

<script>
import Navbar from "../../../../components/Navbar/newNavbar/Navbar.vue";
import Footer from "../../../../components/Footer/newFooter/Footer.vue";

import NewsCards from "./NewsCards.vue";
export default {
  components: {
    Navbar,
    Footer,

    NewsCards,
  },
};
</script>

<style scoped>
.conatiner {
  margin: auto;
}
.mainContainer {
  width: 100%;
  max-width: 1400px;
  min-height: calc(100vh - 190px);
  margin: auto;
}
@media all and (max-width: 1500px) {
  .mainBox {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>